import React from "react";
import Navigate from "./src/Router/Route";
import {  View } from 'react-native';

export default function App() {
  return (
    <View>
      <Navigate />
    </View>
  );
}
