import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { useContext, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Stack from "@mui/material/Stack";

import Paper from "@mui/material/Paper";

import { Button, ImageListItem, ImageList, Box } from "@mui/material";

import moment from "moment";
import {
  StyleSheet,
  Text,
  SafeAreaView,
  ScrollView,
  StatusBar,
} from "react-native";

import { Redirect, useHistory, useParams } from "react-router-dom";

const UserCard = ({ item }) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const time = moment(item.createdAt).fromNow();

  return (
    <List style={{ maxHeight: "100%", overflow: "scroll" }}>
      
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid
                item
                xs
                onClick={() => {
                  history.push(`/expenses?id=${item._id}`);
                }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <PersonIcon fontSize="small" />
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {item.name}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <AlternateEmailIcon />
                  <Typography variant="body2" gutterBottom>
                    {item.email}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <PhoneIphoneIcon />
                  <Typography variant="body2" color="text.secondary">
                    {item.phoneNo}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
                {item.roles}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </List>
  );
};

export default UserCard;
