import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Redirect, useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
// import { makeStyles } from "@mui/styles";
import { CssBaseline } from "@mui/material";
// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: 300,
//   },
// }));

export default function ButtonAppBar(props) {
  const history = useHistory();
  // const classes = useStyles();
  const userInfo = localStorage.getItem("userInfo");
  let currentUser = JSON.parse(userInfo);

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <Drawer open={open} onClose={() => setOpen(false)}>
          <List disablePadding style={{ width: 300 }}>
            <ListItem button>
              <ListItemText
                primary="Expenses Dashboard"
                onClick={() => {
                  history.push("/expenses");
                }}
              />
            </ListItem>
            {currentUser?.roles.includes("Accountant") ? (
              <ListItem button>
                <ListItemText
                  primary="Users"
                  onClick={() => {
                    history.push("/users");
                  }}
                />
              </ListItem>
            ) : (
              <> </>
            )}

            {currentUser?.roles.includes("Employee") ? (
              <ListItem button>
                <ListItemText
                  primary="Add Expense"
                  onClick={() => {
                    history.push("/expensesform");
                  }}
                />
              </ListItem>
            ) : (
              <> </>
            )}

            <ListItem button>
              <ListItemText
                primary="Logout"
                onClick={() => {
                  localStorage.removeItem("userInfo");
                  {
                    history.push("/login");
                  }
                }}
              />
            </ListItem>
          </List>
        </Drawer>
        <AppBar position="static" style={{ background: "#FFFFFF" }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setOpen(true)}
            >
              <MenuIcon style={{ color: "black" }} />

              <Grid>
                <Typography
                  variant="h5"
                  component="div"
                  color="black"
                  sx={{ flexGrow: 1 }}
                >
                  {props.name}
                </Typography>
              </Grid>
            </IconButton>

            <Button
              color="inherit"
              onClick={() => {
                {
                  history.push("/create");
                }
              }}
            >
              {props.user}
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}
