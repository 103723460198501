import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ExpensesForm from "../UI/ExpensesForm";
import HomePage from "../UI/Expenses";
import LogIn from "../UI/LogIn";
import ForgotPassword from "../UI/ForgotPassword";
import ResetPassword from "../UI/ResetPassword";
import AccountsTable from "../components/accounts/AccountsTable";
import UpdateExpense from "../UI/UpdateExpense";
import AccountsView from "../UI/AccountsView";
import CreateUser from "../UI/CreateUser";
import Try from "../UI/Try";


export default function Navigate() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LogIn />
        </Route>
        <Route path="/expensesform">
          <ExpensesForm />
        </Route>
        
      
        <Route exact path="/login">
          <LogIn />
        </Route>
        <Route path="/expenses">
          <HomePage />
        </Route>
     
        <Route path="/fp">
          <ForgotPassword />
        </Route>
        <Route exact path="/reset/:userId/:token">
          <ResetPassword />
        </Route>
    
        <Route path="/Update/:_id">
          <UpdateExpense />
        </Route>
        <Route path="/users">
          <AccountsView />
        </Route>
        <Route path="/create">
          <CreateUser />
        </Route>
        <Route path="/try">
          <Try />
        </Route>
        <Route path="/table">
          <AccountsTable />
        </Route>
      </Switch>
    </Router>
  );
}
