import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { Text, View, ScrollView, SafeAreaView } from "react-native";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useEffect } from "react";
import Boxes from "./Boxes";
import ButtonAppBar from "../Header";
import Stack from "@mui/material/Stack";
import getExpenses from "../../Services/ExpenseService";
import { Redirect, useHistory, useParams, useLocation, Link } from "react-router-dom";
import moment from "moment";
import { Container, TablePagination } from "@mui/material";
import axios from "axios";
import { Avatar, Grid, Typography, Button } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DoneIcon from "@mui/icons-material/Done";
import updateExpenses from "../../Services/UpdateService";
import CloseIcon from '@mui/icons-material/Close';
import DangerousIcon from "@mui/icons-material/Dangerous";
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from "../Drawer";
import UsersBox from "./UsersBox";
import {BASE_API_URL} from '../../config';

export default function BasicTable(item) {
  const userInfo = localStorage.getItem("userInfo");
  let currentUser = JSON.parse(userInfo);
  const history = useHistory();
  const [list, setList] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [isLoaded, setLoaded] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = React.useState({ invoiceCreatedDate: -1 });
  

  const isMobileScreen = !useMediaQuery("(min-width:600px)");
  
  const currencySymbol = (currency='USD') => {
    const currencyMap = {
      'USD': '\u0024',
      'INR': '\u20B9',
      'GBP': '\u00A3',
      'EUR': '\u20A0',
      'AED': 'AED',
      'SGD': 'SGD',
    }
    return currencyMap[currency]||currency;
  }
  function toggleSort(sortKey) {
    if (sort[sortKey] == -1) {
      setSort({ ...sort, [sortKey]: 1 });
    } else {
      setSort({ ...sort, [sortKey]: -1 });
    }
    setQuery({ ...query, sort });
  }
  const removeSortField = (field) => {
    const {[field]:removedField, ...updatedSort} = sort;
    setSort(updatedSort);
    setQuery({ ...query, sort });
  }
  const queryParams = useQuery();

  const [query, setQuery] = React.useState({
    filter: {
      status: (currentUser?.roles?.includes('Accountant') ? 'pending': 'open'),
      ...(queryParams.get("id") ? { user: queryParams.get("id") } : {}),
    },
    keyword: "",
    limit:rowsPerPage,
    sort,
    page,
  });

  const setSearchQuery = (keyword) => {
    const newQuery = { ...query, keyword };

    setQuery(newQuery);
  };

  const setSearchFilter = (filter) => {
    setQuery({ ...query, filter });
  };

  const previousQuery = usePrevious(JSON.stringify(query));
  React.useEffect(async () => {
    if (previousQuery && previousQuery == JSON.stringify(query)) return;
    const { items = [], count, pages, page } = await getExpenses(query, page);

    setList(items);
    setCount(parseInt(count));
    setPage(parseInt(page));
    setLoaded(true);
  });

  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  const handleDelete = (_id) => {
    axios
      .post(`${BASE_API_URL}/delete/${_id}`)
      .then((res) => {
        alert("Deleted Successfully");
      });
    setQuery(query);
  };

  const handleChangePage = (newPage) => {
    setQuery({...query, page: newPage});
  };

  const handleChangeRowsPerPage = (event) => {
    const {target: {value: limit}={}} = event;
    setRowsPerPage(parseInt(limit, 10));
    setPage(0);
    setQuery({...query, page, limit});
  };

  if (!isLoaded) 
    return <Text>Loading...</Text>
  return (
    <SafeAreaView>
      {currentUser?.roles.includes("Employee") ? (
        <UsersBox setSearchFilter={setSearchFilter} filter={query.filter} />
      ) : (
        <> </>
      )}
      {currentUser?.roles.includes("Accountant") ? (
        <Boxes setSearchFilter={setSearchFilter} filter={query.filter} />
      ) : (
        <> </>
      )}
      <Stack style={{ padding: "8px 24px", marginBottom: '32px'}} column='row'>
        {
          !isMobileScreen && <Stack direction="row" alignItems="center" gap={1} style={{ marginBottom: '16px'}}>
          <Grid item container justifyContent="flex-end">
            <Stack direction="row" alignItems="center" gap={6}>
              <Button size='small' variant='contained' onClick={() => {history.push("/expensesform")}}>Create Expense</Button>
            </Stack>
          </Grid>
          </Stack>
        }
        
        <Stack direction="row" alignItems="center" gap={1}>
          <FilterListIcon size="small" />
          <Typography
            variant="caption text"
            style={{
              fontFamily: "Inter_400Regular",
              fontSize: 16,
              fontWeight: 400,

              letterSpacing: 0,
              AlignText: "left",
              color: "#0A0A0A",
            }}
            onClick={() => toggleSort("invoiceCreatedDate")}
          >
            ExpenseDate
          </Typography>
          <Grid item container justifyContent="flex-end">
            <Stack direction="row" alignItems="center" gap={6}>
              <Drawer setSearchFilter={setSearchFilter} filter={query.filter} />
            </Stack>
          </Grid>
        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {currentUser?.roles.includes("Accountant") && (
                  <TableCell>Employee Name</TableCell>
                )}
                <TableCell>
                  <Stack direction='row'>
                    Merchant Name
                    <TableSortLabel
                      direction={sort['merchantname'] == -1 ? 'asc' : 'desc'}
                      onClick={() => toggleSort('merchantname')}
                    />
                    {
                      sort['merchantname'] ? 
                        <CloseIcon onClick={() => removeSortField('merchantname')} color="disabled" fontSize="small" sx={{alignSelf: 'center'}} /> 
                        : <CloseIcon fontSize="small" sx={{alignSelf: 'center', color: '#ffffff'}} /> 
                    }
                  </Stack>
                  
                </TableCell>

                <TableCell>
                <Stack direction="row">
                  Invoice Date
                    <TableSortLabel
                        direction={sort['invoiceCreatedDate'] == -1 ? 'asc' : 'desc'}
                        onClick={() => toggleSort('invoiceCreatedDate')}
                    />
                    {
                      sort['invoiceCreatedDate'] ? 
                        <CloseIcon onClick={() => removeSortField('invoiceCreatedDate')} color="disabled" fontSize="small" sx={{alignSelf: 'center'}} /> 
                        : <CloseIcon fontSize="small" sx={{alignSelf: 'center', color: '#ffffff'}} /> 
                    }
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="row">
                    Invoice Amount
                    <TableSortLabel
                      direction={sort['invoiceValue'] == -1 ? 'asc' : 'desc'}
                      onClick={() => toggleSort('invoiceValue')}
                    />
                    {
                      sort['invoiceValue'] ?
                       <CloseIcon onClick={() => removeSortField('invoiceValue')} color="disabled" fontSize="small" sx={{alignSelf: 'center'}} />
                       : <CloseIcon fontSize="small" sx={{alignSelf: 'center', color: '#ffffff'}} /> 
                    }
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="row">
                    Category
                    <TableSortLabel
                      direction={sort['category'] == -1 ? 'asc' : 'desc'}
                      onClick={() => toggleSort('category')}
                    />
                    {
                      sort['category'] ? 
                        <CloseIcon onClick={() => removeSortField('category')} color="disabled" fontSize="small" sx={{alignSelf: 'center'}} />
                        : <CloseIcon fontSize="small" sx={{alignSelf: 'center', color: '#ffffff'}} /> 
                    }
                  </Stack>
                </TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>File</TableCell>
                {
                  ((currentUser?.roles.includes("Accountant")  && query?.filter?.status == "pending")) ?
                    <TableCell colSpan={4}>Actions</TableCell>
                    : (currentUser?.roles.includes("Accountant")) ? <TableCell colSpan={2}>Actions</TableCell> :
                    (query?.filter?.status == "open") ? <TableCell colSpan={4}></TableCell> : <TableCell colSpan={1}></TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length ? (
                list.map(({_id, ...item}) => (
                    <TableRow key={_id}>
                      {
                        currentUser?.roles.includes("Accountant") &&
                        <TableCell>{item.user.name}</TableCell>
                      }
                      <TableCell>{item.merchantname}</TableCell>

                      <TableCell>
                        {moment(item.invoiceCreatedDate).format("DD-MM-YYYY")}
                      </TableCell>

                      {/* <TableCell>{item.invoiceImg}</TableCell> */}
                      <TableCell>
                        <Stack direction="row" alignItems="center">
                          <Text style={{ fontSize: '16px', fontWeight:500, marginRight: '2px'  }} >{currencySymbol(item.currency)}</Text>
                          {item.invoiceValue}
                        </Stack>
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={item.category}
                          variant="outlined"
                          style={{
                            color:
                              (item.category === "Tech Expenses " &&
                                "#FFA500") ||
                              (item.category === "Food" && "#CC1D92") ||
                              (item.category === "Transport" && "#0073BA") ||
                              (item.category === "Client Meeting" && "#8F49DA"),
                          }}
                        ></Chip>
                      </TableCell>
                      <TableCell  style={{textOverflow: 'ellipsis', overflow: 'auto', maxWidth: '200px'}}>
                        <Text>{(item.description?.length > 50) ? `${item.description.slice(0, 50)}...`: `${item?.description||''}`}</Text>
                      </TableCell>
                      <TableCell>
                        <Link style={{ cursor: 'pointer', textDecoration:'none' }}  to={{pathname:item.fileUrl}} target='_blank'>Open</Link>
                      </TableCell>
                      {
                        currentUser?.roles.includes("Accountant") &&
                        item?.status.includes("pending") && 
                        <TableCell style={{ color: "#077D55" }}>
                          <Stack direction="row" alignItems="center"  style={{ cursor: 'pointer' }}  onClick={() =>updateExpenses(_id, { status: "approved" })}>
                            <DoneIcon style={{ color: "#077D55" }}/>
                            Approve
                          </Stack>
                        </TableCell>
                      }
                      {
                        currentUser?.roles.includes("Accountant") &&
                        item?.status.includes("pending") && 
                        <TableCell style={{ color: "#D91F11" }} >
                          <Stack direction="row" alignItems="center" style={{ cursor: 'pointer' }} onClick={() => updateExpenses(_id, { status: "rejected" })}>
                            <DangerousIcon style={{ color: "#D91F11" }}/>
                            Reject
                          </Stack>
                        </TableCell>
                      }
                      {
                        currentUser?.roles.includes("Employee") &&
                        item?.status.includes("open") && 
                        <TableCell style={{ color: "#077D55" }} >
                          <Stack direction="row" alignItems="center" style={{ cursor: 'pointer' }} onClick={() => updateExpenses(_id, { status: "pending" })}>
                           <DoneIcon style={{ color: "#077D55" }}/>
                            Submit
                          </Stack>
                        </TableCell>
                      }
                      {
                        currentUser?.roles.includes("Employee") &&
                        item?.status.includes("open") && 
                        <TableCell style={{ color: "#186ADE" }}>
                          <Stack direction="row" alignItems="center" style={{ cursor: 'pointer', textDecoration:'none' }} component={Link} to={`/Update/${_id}`}>
                            <ModeEditIcon
                              style={{ color: "#186ADE" }}
                            />
                            Edit
                          </Stack>
                        </TableCell>
                      }
                      {
                        currentUser?.roles.includes("Accountant") && 
                        <TableCell style={{ color: "#186ADE" }}>
                          <Stack direction="row" alignItems="center" style={{ cursor: 'pointer', textDecoration:'none' }} component={Link} to={`/Update/${_id}`}>
                            <ModeEditIcon
                              style={{ color: "#186ADE" }}
                            />
                            View
                          </Stack>
                        </TableCell>
                      }
                      { currentUser?.roles.includes("Employee") &&
                        item?.status.includes("open") &&  
                        <TableCell style={{ color: "#D91F11" }}>
                          <Stack direction="row" alignItems="center" style={{ cursor: 'pointer' }} onClick={() => handleDelete(_id)}>
                            <DeleteOutlineIcon
                            color="secondary"
                            style={{ color: "#D91F11" }}
                          />
                          Delete
                          </Stack>
                        </TableCell>
                      }
                      {
                        ((currentUser?.roles.includes("Accountant")  && query?.filter?.status == "pending")) ?
                          <TableCell colSpan={4}></TableCell>
                          : (currentUser?.roles.includes("Accountant")) ? <TableCell colSpan={2}></TableCell> :
                          (query?.filter?.status == "open") ? <TableCell colSpan={4}></TableCell> : <TableCell colSpan={1}></TableCell>
                      }
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <View>
                      <Text>Uh Oh! Your expense list is empty.</Text>
                    </View>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
              {
                (count > 0) && <TablePagination
                rowsPerPageOptions={[10, 20, 30, 50]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, pageNumber) => handleChangePage(pageNumber)}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
              />
              }
              </TableRow>
            </TableFooter>
          </Table>
          
        </TableContainer>
      </Stack>
    </SafeAreaView>
  );
}
