import React, { Component } from 'react';
import { SafeAreaView, StyleSheet, Platform } from "react-native";
import { WebView } from 'react-native-webview';
import Link from '@mui/material/Link';

export default class WebViewInvoice extends Component {
  
  render() {
    const {uri, fileMimeType=''} = this.props;
    const getFileExtension = (fileMimeType) =>  {
      const match = fileMimeType.match(/\/([0-9a-z]+)$/);
      if (!match)
        return fileMimeType;
      return match[1].toUpperCase();
    }
    const fileExtension = getFileExtension(fileMimeType);
    if (!['application/pdf', 'image/jpeg', 'image/png',].includes(fileMimeType)) {
      return <SafeAreaView style={styles.downloadContainer}>
        <Link rel="noopener"  target='_blank' href={uri}>Download {fileExtension} file</Link>
      </SafeAreaView>
    }
    return Platform.OS === "web" ? 
      (<iframe src={uri} height={'100%'} width={'100%'} style={{flexGrow: 1}} />) : 
      (<SafeAreaView style={styles.container}>
          <WebView source={{ uri }} style={styles.view} />
      </SafeAreaView>)
  }
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',

  },
  downloadContainer: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  view: {
    marginTop: 20,
    maxHeight: 200,
    width: 320,
    flex: 1
  }
})