import React, { useContext, useState, useEffect, Fragment } from "react";

import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Platform,
  StyleSheet,
  ScrollView,
  ImageBackground,
  ActivityIndicator,
  Alert,
} from "react-native";
import axios from "axios";
import FormInput from "../components/FormInput";
import FormButton from "../components/FormButton";
import SocialButton from "../components/SocialButton";

import ErrorMessage from "../components/ErrorMessage";
import { Redirect, useHistory, useParams } from "react-router-dom";
import {BASE_API_URL} from '../config';

const ResetPassword = ({ navigation, SignupScreen }) => {
  const history = useHistory();
  const [validUrl, setValidUrl] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isAuthorised, setAuthorised] = useState(false);

  const param = useParams();
  const { userId, token } = useParams();
  const url = `${BASE_API_URL}/api/password-reset/${param.userId}/${param.token}`;

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!password) {
      setMessage("Please fill password");
      return;
    } else {
      setMessage(null);
      try {
        const config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        setLoading(true);

        const response = await axios.post(url, { password }, config);
        const { data } = response;
        {
          setMessage("New password generated");
        }
        history.push("/login");
        setLoading(false);
      } catch (error) {
        setError(error.response.data.message);
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.text}>Reset Password</Text>

      <FormInput
        labelValue={password}
        value={password}
        onChangeText={(e) => setPassword(e)}
        placeholderText="Password"
        iconType="lock"
        secureTextEntry={true}
      />
      {message && <ErrorMessage variant="danger">{message}</ErrorMessage>}
      {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
      {isLoading && <ActivityIndicator />}

      <FormButton buttonTitle="Reset" onPress={submitHandler} />
    </ScrollView>
  );
};

export default ResetPassword;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    paddingTop: 50,
  },
  logo: {
    height: "100%",
    width: "100%",
    resizeMode: "cover",
    flex: 1,
  },
  text: {
    fontFamily: "Kufam-SemiBoldItalic",
    fontSize: 28,
    marginBottom: 10,
    color: "#051d5f",
  },
  navButton: {
    marginTop: 15,
  },
  forgotButton: {
    marginVertical: 35,
  },
  navButtonText: {
    fontSize: 18,
    fontWeight: "500",
    color: "#2e64e5",
    fontFamily: "Lato-Regular",
  },
});
