import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { SafeAreaView } from "react-native";
import { Button } from "@mui/material";
import ButtonAppBar from "../components/Header";

import { Hidden } from "@mui/material";

import ExpenseList from "../components/expense-list/index";
import BasicTable from "../components/accounts/Table";

const HomePage = () => {
  return (
    <SafeAreaView>
      <ButtonAppBar
        name="Shippay"
      />
      <Hidden smUp>
        <ExpenseList />
      </Hidden>

      <Hidden smDown>
        <BasicTable />
      </Hidden>
    </SafeAreaView>
  );
};

export default HomePage;
