
import getQueryString from "../utils/GetQueryString";
import {BASE_API_URL} from '../config';

const getSingleExpenses = async ({ ...query }) => {
  const api = `${BASE_API_URL}/findUser?${getQueryString({...query})}`;
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await fetch(api, config);

    const json = await response.json();

    return json;
  } catch (error) {
    console.error(error);
  }
};

export default getSingleExpenses;
