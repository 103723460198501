import {BASE_API_URL} from '../config';

const getSingleExpenses = async (id) => {
  console.log(id);
  const api = `${BASE_API_URL}/singleExpense/${id}`;
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await fetch(api, config);

    const json = await response.json();

    return { ...json, status: response.status };
  } catch (error) {
    console.error(error);
  }
};

export default getSingleExpenses;
