import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { useContext, useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PersonIcon from "@mui/icons-material/Person";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DoneIcon from "@mui/icons-material/Done";
import { Swipeable } from "react-native-gesture-handler";
import DescriptionIcon from "@mui/icons-material/Description";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { StorefrontOutlined } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ButtonBase from "@mui/material/ButtonBase";
import { Button, ImageListItem, ImageList, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { useFonts, Inter_400Regular } from "@expo-google-fonts/inter";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import updateExpenses from "../../Services/UpdateService";
import moment from "moment";
import {
  StyleSheet,
  View,
  Text,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Animated,
  TouchableOpacity,
} from "react-native";

import { Redirect, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {BASE_API_URL} from '../../config';

const Card = ({ item }) => {
  const { _id } = item;
  const theme = createTheme();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const time = moment(item.createdAt).fromNow();
  const [isLoaded, setIsLoaded] = useState(false);
  const [update, setUpdate] = React.useState([]);

  let [fontsLoaded] = useFonts({
    Inter_400Regular,
  });
  const currencySymbol = (currency='USD') => {
    const currencyMap = {
      'USD': '\u0024',
      'INR': '\u20B9',
      'GBP': '\u00A3',
      'EUR': '\u20A0',
      'AED': 'AED',
      'SGD': 'SGD',
    }
    return currencyMap[currency]||currency;
  }
  const rightSwipe = (progress, dragX) => {
    const scale = dragX.interpolate({
      inputRange: [-100, 0],
      outputRange: [1, 0],
      extrapolate: "clamp",
    });

    return (
      <SafeAreaView>
        {currentUser?.roles.includes("Employee") &&
        item?.status.includes("open") ? (
          <TouchableOpacity
            onPress={() => handleDelete(item._id)}
            activeOpacity={0.6}
          >
            <View style={styles.deleteBox}>
              <DeleteOutlineIcon
                style={{ alignItems: "center", color: "#FFFFFF" }}
              />
              <Text
                style={{
                  color: "#FFFFFF",
                  paddingHorizontal: 10,
                  fontWeight: "600",
                  transform: [{ scale }],
                }}
              >
                Delete
              </Text>
            </View>
          </TouchableOpacity>
        ) : (
          <> </>
        )}
      </SafeAreaView>
    );
  };
  const editSwipe = (progress, dragX) => {
    const scale = dragX.interpolate({
      inputRange: [-100, 0],
      outputRange: [1, 0],
      extrapolate: "clamp",
    });
    return (
      <SafeAreaView>
        {currentUser?.roles.includes("Employee") &&
        item?.status.includes("open") ? (
          <TouchableOpacity
            onPress={() => {
              history.push(`/Update/${item._id}`);
            }}
            activeOpacity={0.6}
          >
            <View style={styles.editBox}>
              <ModeEditIcon
                style={{ alignItems: "center", color: "#FFFFFF" }}
              />
              <Text
                style={{
                  color: "#FFFFFF",
                  paddingHorizontal: 10,
                  fontWeight: "600",
                  transform: [{ scale }],
                }}
              >
                Edit
              </Text>
            </View>
          </TouchableOpacity>
        ) : (
          <> </>
        )}
      </SafeAreaView>
    );
  };
  const saveSwipe = (progress, dragX) => {
    const scale = dragX.interpolate({
      inputRange: [-100, 0],
      outputRange: [1, 0],
      extrapolate: "clamp",
    });
    return (
      <SafeAreaView>
        {currentUser?.roles.includes("Employee") &&
        item?.status.includes("open") ? (
          <TouchableOpacity
            onPress={() => updateExpenses(_id, { status: "pending" })}
            activeOpacity={0.6}
          >
            <View style={styles.saveBox}>
              <CheckIcon style={{ alignItems: "center", color: "#FFFFFF" }} />
              <Text
                style={{
                  color: "#FFFFFF",
                  paddingHorizontal: 10,
                  fontWeight: "600",
                  transform: [{ scale }],
                }}
              >
                Submit
              </Text>
            </View>
          </TouchableOpacity>
        ) : (
          <> </>
        )}
      </SafeAreaView>
    );
  };
  const userInfo = localStorage.getItem("userInfo");
  let currentUser = JSON.parse(userInfo);

  const handleDelete = (_id) => {
    axios
      .post(`${BASE_API_URL}/delete/${_id}`)
      .then((res) => {
        alert("Deleted Successfully");
      });
  };

  return (
    <SafeAreaView>
      {/* <Paper sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <AddCircleIcon />
          <Typography variant="body1">text</Typography>
        </Stack>
      </Paper> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <List style={{ maxHeight: "100%", overflow: "scroll" }}>
          <Paper sx={{ p: 2 }}>
            <Swipeable renderRightActions={rightSwipe}>
              <Swipeable renderRightActions={editSwipe}>
                <Swipeable renderRightActions={saveSwipe}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm container>
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        justify="space-between"
                        spacing={2}
                      >
                        <Grid item xs>
                          {currentUser?.roles.includes("Accountant") ? (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <PersonIcon
                                fontSize="small"
                                style={{ color: "#616161" }}
                              />
                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                component="div"
                                style={{
                                  fontFamily: "  Inter_400Regular",
                                  fontSize: 16,
                                  fontWeight: 400,

                                  letterSpacing: 0,
                                  alignText: "left",
                                  color: "#616161",
                                }}
                              >
                                {item.user.name}
                              </Typography>
                            </Stack>
                          ) : (
                            <> </>
                          )}
                          <Stack direction="row" alignItems="center" gap={1}>
                            <StorefrontOutlined
                              fontSize="small"
                              style={{ color: "#616161" }}
                            />
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="div"
                              style={{
                                fontFamily: "  Inter_400Regular",
                                fontSize: 16,
                                fontWeight: 400,

                                letterSpacing: 0,
                                alignText: "left",
                                color: "#616161",
                              }}
                            >
                              {item.merchantname}
                            </Typography>
                          </Stack>

                          <Stack direction="row" alignItems="center" gap={1}>
                            <CalendarTodayIcon
                              fontSize="small"
                              style={{ color: "#616161" }}
                            />
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              style={{
                                fontFamily: " Inter_400Regular",
                                fontSize: 16,
                                fontWeight: 400,

                                letterSpacing: 0,
                                alignText: "left",
                                color: "#616161",
                              }}
                            >
                              {moment(item.invoiceCreatedDate).format(
                                "DD-MM-YYYY"
                              )}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        <Stack direction="row" alignItems="center" gap={1}>
                        <Text style={{ fontSize: '16px', fontWeight:500,  }} >{currencySymbol(item.currency)}</Text>
                          <Typography
                            align="right"
                            variant="subtitle1"
                            component="div"
                            style={{
                              fontFamily: " Inter_400Regular",
                              fontSize: 16,
                              fontWeight: 600,
                              letterSpacing: 0,
                              color: "#0A0A0A",
                              alignText: "right",
                              justifyContent: "right",
                            }}
                          >
                            {item.invoiceValue}
                          </Typography>
                        </Stack>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          container
                          justifyContent="flex-end"
                        >
                          <Stack direction="row" alignItems="center" gap={1}>
                            {currentUser?.roles.includes("Employee") &&
                            item?.status.includes("open") ? (
                              <Chip
                                label="Draft"
                                variant="outlined"
                                style={{
                                  fontFamily: " Inter_400Regular",
                                  fontSize: 12,
                                  fontWeight: 400,
                                  letterSpacing: 0,
                                  color: "#616161",
                                  alignText: "right",
                                }}
                              ></Chip>
                            ) : (
                              <> </>
                            )}

                            <Chip
                              label={item.category}
                              variant="outlined"
                              style={{
                                color:
                                  (item.category === "Tech Expenses " &&
                                    "#FFA500") ||
                                  (item.category === "Food" && "#CC1D92") ||
                                  (item.category === "Transport" &&
                                    "#0073BA") ||
                                  (item.category === "Client Meeting" &&
                                    "#8F49DA"),
                              }}
                            ></Chip>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    {currentUser?.roles.includes("Accountant") &&
                    item?.status.includes("pending") ? (
                      <Grid item xs>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <DoneIcon
                            fontSize="small"
                            style={{ color: "green" }}
                            onClick={() =>
                              updateExpenses(_id, { status: "approved" })
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{ color: "green" }}
                          >
                            Approve
                          </Typography>
                        </Stack>
                      </Grid>
                    ) : (
                      <> </>
                    )}
                    {currentUser?.roles.includes("Accountant") &&
                    item?.status.includes("pending") ? (
                      <Grid item xs>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <DangerousIcon
                            fontSize="small"
                            style={{ color: "red" }}
                            onClick={() =>
                              updateExpenses(_id, { status: "rejected" })
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{ color: "red" }}
                          >
                            Reject
                          </Typography>
                        </Stack>
                      </Grid>
                    ) : (
                      <> </>
                    )}
                  </Grid>
                </Swipeable>
              </Swipeable>
            </Swipeable>
          </Paper>
        </List>
      </ThemeProvider>
    </SafeAreaView>
  );
};

export default Card;
const styles = StyleSheet.create({
  deleteBox: {
    backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center",
    width: 100,
    height: 80,
  },
  editBox: {
    backgroundColor: "blue",
    justifyContent: "center",
    alignItems: "center",
    width: 100,
    height: 80,
  },
  saveBox: {
    backgroundColor: "#077D55",
    justifyContent: "center",
    alignItems: "center",
    width: 100,
    height: 80,
  },
});
