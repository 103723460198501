import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import UserCard from "./UsersCard";

import GetUsers from "../../Services/GetUsers";
import { Text, View } from "react-native";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";

const UsersList = () => {
  const history = useHistory();
  const [list, setList] = React.useState([]);
  const [isLoaded, setLoaded] = React.useState(false);
  const [query, setQuery] = React.useState({ keyword: "", limit: 10, skip: 0 });

  const setSearchQuery = (keyword) => {
    const newQuery = { ...query, keyword };
    setQuery(newQuery);
  };

  const previousQuery = usePrevious(JSON.stringify(query));
  React.useEffect(async () => {
    if (previousQuery && previousQuery == JSON.stringify(query)) return;
    const { data } = await GetUsers(query);
    if (data.length) {
      setList(data);
      setLoaded(true);
    }
  });
  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  if (!isLoaded) return <Text>Loading...</Text>;

  return (
    <View>
      {/* <SearchBar setSearchQuery={setSearchQuery} query={query.keyword} /> */}

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h4" type="left">
              Users
            </Typography>
          </Grid>
        </Grid>

        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <div
            style={{
              margin: 6,

              flexDirection: "column",
              flex: 1,
            }}
          >
            {list.length ? (
              list.map((item) => <UserCard item={item} key={item._id} />)
            ) : (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={
                    "https://cdni.iconscout.com/illustration/free/thumb/empty-list-2100976-1763834.png"
                  }
                  alt="No Expenses"
                  style={{ height: 240 }}
                />
                <label>Uh Oh! Your expense list is empty.</label>
              </div>
            )}
          </div>
        </Paper>
      </Container>
    </View>
  );
};

export default UsersList;
