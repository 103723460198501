import * as React from "react";
import { ActivityIndicator, Text, SafeAreaView, View, Image } from "react-native";
import axios from "axios";
import { WebView } from 'react-native-webview';
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Redirect, useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import ErrorMessage from "../components/ErrorMessage";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ButtonAppBar from "../components/Header";
import WebViewInvoice from './WebViewInvoice';
import CircularProgress from '../components/CircularProgress';

import {
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  FormControl,
  FormLabel,
} from "@mui/material";
import {BASE_API_URL} from '../config';


const theme = createTheme();
const defaultValues = {
  username: "",
  merchantname: "",
  invoiceCreatedDate: "",

  invoiceValue: "",

  category: "",
  description: "",
  createdAt: new Date(),
  status: "open",
  currency: 'INR',
  exchangeRate: 1,
};

export default function ExpensesForm() {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [picMessage, setPicMessage] = useState(null);
  const [invoiceFile, setInvoiceFile] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [message, setMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const {
      merchantname,
      invoiceCreatedDate,

      invoiceValue,

      category,
      currency = defaultValues.currency,
      exchangeRate,
    } = formValues;

    const getToken = localStorage.getItem("userInfo");
    let Token = JSON.parse(getToken);

    fetch(`${BASE_API_URL}/Expensesform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token.token}`,
      },
      body: JSON.stringify({
        merchantname,
        invoiceCreatedDate,
        invoiceValue,
        file: invoiceFile,
        category,
        currency,
        exchangeRate,
        status: "open",
        createdAt: new Date(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data._id)
          history.push("/expenses");
        else
          return setPicMessage("Failed to add invoice");
      });
  };
  const postDetails = async (pics) => {
    const getToken = localStorage.getItem("userInfo");
    let {token} = JSON.parse(getToken)||{};
    setPicMessage(null);
    if (['application/zip', 'application/pdf', 'image/jpeg', 'image/png',].includes(pics.type)) {
      setIsUploading(true);
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "Invoice");
      const {data:uploadedFileDetails, status,} = await axios.post(`${BASE_API_URL}/upload`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((100 * progressEvent.loaded) / progressEvent.total);
          console.log(percentage);
          setUploadProgress(percentage);
        }
      });
      setIsUploading(false);
      if (status != 200)
        return console.error('Failed to upload');
      setInvoiceFile(uploadedFileDetails);
    } else {
      return setPicMessage("Please Select a JPEG/PNG/PDF file");
    }
  };
  return (
    <SafeAreaView>
      <ButtonAppBar
        name="Shippay"
      />
      <form onSubmit={handleSubmit}>

        <Container component="main" maxWidth="false" sx={{ mb: 4, mt: 4 }}>
          <Card>
            <CardContent  sx={{ pr: 2, pl: 2 }}>
              <Grid container spacing={2}>
                <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center'>
                  <Grid item xs={3} >
                    <Typography component="h1" variant="h4" type="center" sx={{ mb: 2, mt: 4 }}>
                      Add New Expense
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {
                    isUploading && <Box style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress value={uploadProgress}/></Box>
                  }
                  {
                    !isUploading && (
                    (invoiceFile?.url) ? 
                    <View style={{height: '100%', display: 'flex'}}>
                       <WebViewInvoice uri={invoiceFile.url} fileMimeType={invoiceFile.contentType} />
                       <Button variant="outlined" component="label" color="primary" sx={{mt:1}}>
                          Change Upload
                          <input
                            type="file"
                            name="invoiceFile"
                            value={formValues.invoiceFile}
                            onChange={(e) => postDetails(e.target.files[0])}
                            hidden
                          />
                      </Button>
                    </View>
                    :
                     <Box component="div" display='flex' alignItems='center' justifyContent='center' sx={{ p: 2, border: '1px dashed ', height:'100%' }}>
                      <Button variant="outlined" component="label" color="primary">
                          Upload
                          <input
                            accept="image/jpeg/pdf"
                            type="file"
                            name="invoiceFile"
                            value={formValues.invoiceFile}
                            onChange={(e) => postDetails(e.target.files[0])}
                            hidden
                          />
                      </Button>
                    </Box>
                    )
                  }
                  
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        variant="outlined"
                        id="outlined-required"
                        name="merchantname"
                        label="Merchant Name"
                        size="small"
                        type="text"
                        fullWidth
                        value={formValues.merchantname}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        id="outlined-required"
                        name="invoiceCreatedDate"
                        helperText="Invoice created date"
                        type="date"
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        size="small"
                        value={formValues.invoiceCreatedDate}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        variant="outlined"
                        id="outlined-required"
                        size="small"
                        fullWidth
                        required
                      >
                        <InputLabel> Select Category</InputLabel>
                        <Select
                          label="Enter Category"
                          name="category"
                          value={formValues.category}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={"Food"}>Food</MenuItem>
                          <MenuItem value={"Transport"}>Transport</MenuItem>
                          <MenuItem value={"Client Meeting"}>Client Meeting</MenuItem>
                          <MenuItem value={"Tech Expenses "}>Tech Expenses </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="invoiceValue"
                        variant="outlined"
                        id="outlined-required"
                        size="small"
                        type="number"
                        label="Enter Invoice value"
                        fullWidth
                        autoComplete="Iv"
                        required
                        value={formValues.invoiceValue}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        id="outlined-required"
                        size="small"
                        fullWidth
                        required
                      >
                        <InputLabel> Select Currency</InputLabel>
                        <Select
                          label="Select Currency"
                          name="currency"
                          value={formValues.currency}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={'USD'}>USD</MenuItem>
                          <MenuItem value={'INR'}>INR</MenuItem>
                          <MenuItem value={'AED'}>AED</MenuItem>
                          <MenuItem value={'EUR'}>EUR</MenuItem>
                          <MenuItem value={'GBP'}>GBP</MenuItem>
                          <MenuItem value={'SGD'}>SGD</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        id="outlined-required"
                        size="small"
                        fullWidth
                        required
                      >
                        <TextField
                          name="exchangeRate"
                          variant="outlined"
                          id="outlined-required"
                          size="small"
                          type="number"
                          label="Enter Exchange Rate"
                          required
                          default={1}
                          value={formValues.exchangeRate}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        name="description"
                        label="Enter description"
                        multiline
                        fullWidth
                        rows="3"
                        margin="normal"
                        variant="outlined"
                        value={formValues.description}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <React.Fragment>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="submit" sx={{ mt: 3, ml: 1 }} variant="contained">
                    Submit
                  </Button>

                  <Button
                    noValidate
                    type="submit"
                    sx={{ mt: 3, ml: 1 }}
                    variant="contained"
                    onClick={() => {
                      history.push("/expenses");
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </React.Fragment>
            </CardContent>
          </Card>
        </Container>
      </form>
    </SafeAreaView>
  );
}
