import * as React from "react";
import {BASE_API_URL} from '../config';

const updateExpenses = async (id, data) => {
  const getToken = localStorage.getItem("userInfo");
  let Token = JSON.parse(getToken);
  const api = `${BASE_API_URL}/update/${id}`;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token.token}`,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(api, config);

    const json = await response.json();

    return json;
  } catch (error) {
    console.error(error);
  }
};

export default updateExpenses;
