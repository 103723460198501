import React from "react";
import Card from "./card";

import Grid from "@mui/material/Grid";
import getExpenses from "../../Services/ExpenseService";
import { Text, View, ScrollView, SafeAreaView } from "react-native";
import { Paper } from "@mui/material";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useFonts, Inter_400Regular } from "@expo-google-fonts/inter";
import { Button } from "@mui/material";
import Drawer from "../Drawer";
import Try from "../../UI/Try";
import moment from "moment";
import UserBox from "./UserBox";

const ExpenseList = () => {
  const [list, setList] = React.useState([]);
  const [isLoaded, setLoaded] = React.useState(false);
  const [sort, setSort] = React.useState({ invoiceCreatedDate: -1 });
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);

  function handlePrevious() {
    setPage((p) => {
      if (p === 1) return p;
      return p - 1;
    });
  }

  function handleNext() {
    setPage((p) => {
      if (p === pageCount) return p;
      return p + 1;
    });
  }

  function toggleSort(sortKey) {
    if (sort[sortKey] == -1) {
      setSort({ ...sort, [sortKey]: 1 });
    } else {
      setSort({ ...sort, [sortKey]: -1 });
    }
    setQuery({ ...query, sort });
  }
  const queryParams = useQuery();

  const [query, setQuery] = React.useState({
    filter: {
      status: "open",
      ...(queryParams.get("id") ? { user: queryParams.get("id") } : {}),
    },
    keyword: "",
    limit: 10,
    skip: 0,
    sort,
  });

  const setSearchQuery = (keyword) => {
    const newQuery = { ...query, keyword };

    setQuery(newQuery);
  };

  const setSearchFilter = (filter) => {
    setQuery({ ...query, filter });
  };

  const previousQuery = usePrevious(JSON.stringify(query));
  React.useEffect(async () => {
    if (previousQuery && previousQuery == JSON.stringify(query)) return;
    const { items = [] } = await getExpenses(query, page);

    setList(items);
    setPageCount(items.pageCount);
    setLoaded(true);
  });

  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let [fontsLoaded] = useFonts({
    Inter_400Regular,
  });

  // if (!isLoaded) return <Text>Loading...</Text>;
  const userInfo = localStorage.getItem("userInfo");
  let currentUser = JSON.parse(userInfo);

  return (
    <SafeAreaView>
      {currentUser?.roles.includes("Accountant") ? (
        <Try setSearchFilter={setSearchFilter} filter={query.filter} />
      ) : (
        <> </>
      )}
      {currentUser?.roles.includes("Employee") ? (
        <UserBox setSearchFilter={setSearchFilter} filter={query.filter} />
      ) : (
        <> </>
      )}

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <FilterListIcon size="small" />
          <Typography
            variant="caption text"
            style={{
              fontFamily: "Inter_400Regular",
              fontSize: 16,
              fontWeight: 400,

              letterSpacing: 0,
              AlignText: "left",
              color: "#0A0A0A",
            }}
            onClick={() => toggleSort("invoiceCreatedDate")}
          >
            ExpenseDate
          </Typography>
          <Grid item container justifyContent="flex-end">
            <Stack direction="row" alignItems="center" gap={6}>
              <Drawer setSearchFilter={setSearchFilter} filter={query.filter} />
            </Stack>
          </Grid>
        </Stack>

        {/* <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        > */}
        <View>
          {/* <SearchBar setSearchQuery={setSearchQuery} query={query.keyword} /> */}
          {/* <OutlineBox setSearchFilter={setSearchFilter} filter={query.filter} /> */}
          <div
            style={{
              margin: 6,
              justifyContent: "center",
                  alignItems: "center",
              flexDirection: "column",
             
            }}
          >
            {list.length ? (
              list.map((item) => <Card item={item} key={item._id} />)
            ) : (
              <div
                style={{
                  display:"flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* <img
                  src={
                    "https://cdni.iconscout.com/illustration/free/thumb/empty-list-2100976-1763834.png"
                  }
                  alt="No Expenses"
                  style={{ height: 240 }}
                /> */}
                <label style={{justifyContent:"center"}}>Uh Oh! Your expense list is empty.</label>
              </div>
            )}
          </div>
          {/* <Button disabled={page === 1} onClick={handlePrevious}>
            Previous
          </Button>
          <Button disabled={page === pageCount} onClick={handleNext}>
            Next
          </Button> */}
        </View>
      </Container>
    </SafeAreaView>
  );
};
export default ExpenseList;
