import * as React from "react";
import PropTypes from "prop-types";
// import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Container, Grid, FormGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import BasicDateRangePicker from "./RangePicker";
import { Chip, Stack, Paper } from "@mui/material";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function Drawer(props) {
  const { window, setSearchFilter, filter } = props;
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [categoryFilter, setCategoryFilter] = React.useState([]);
  const [merchantnameFilter, setMerchantNameFilter] = React.useState('');

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const setFilterData = () => {
    console.log(merchantnameFilter);
    setSearchFilter({
      ...filter,
      category: categoryFilter,
      merchantname: merchantnameFilter,
      invoiceCreatedDate: dateRange,
    });
    setOpen(false);
  };

  const toggleCategory = (category) => {
    if (categoryFilter.includes(category)) {
      setCategoryFilter(categoryFilter.filter((e) => category != e));
    } else {
      setCategoryFilter([...categoryFilter, category]);
    }
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      {/* <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      /> */}
      <Box sx={{ textAlign: "center", pt: 1 }}>
        <FilterAltIcon onClick={toggleDrawer(true)} />
      </Box>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Typography sx={{ p: 2, color: "text.secondary" }}></Typography>
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Typography
              variant="subtitle1"
              component="div"
              style={{ marginBottom: 10 }}
            >
              Categories:
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              gap={3}
              style={{ marginBottom: 10 }}
            >
              <Chip
                label="Food"
                variant="outlined"
                style={{
                  color: categoryFilter.includes("Food") ? "#CC1D92" : "grey",
                }}
                onClick={() => toggleCategory("Food")}
              ></Chip>
              <Chip
                label="Client Meeting"
                variant="outlined"
                style={{
                  color: categoryFilter.includes("Client Meeting")
                    ? "#8F49DA"
                    : "grey",
                }}
                onClick={() => toggleCategory("Client Meeting")}
              ></Chip>
              <Chip
                label="Transport"
                variant="outlined"
                style={{
                  color: categoryFilter.includes("Transport")
                    ? "#0073BA"
                    : "grey",
                }}
                onClick={() => toggleCategory("Transport")}
              ></Chip>
              <Chip
                label="Tech Expenses"
                variant="outlined"
                style={{
                  color: categoryFilter.includes("Tech Expenses ")
                    ? "#FFA500"
                    : "grey",
                }}
                onClick={() => toggleCategory("Tech Expenses ")}
              ></Chip>
            </Stack>
            <Typography
              variant="subtitle1"
              component="div"
              style={{ marginBottom: 10 }}
            >
              Merchant Name:
            </Typography>
            <TextField
              name="merchantname"
              variant="outlined"
              id="outlined-required"
              size="small"
              type="text"
              label="Merchant Name"
              value={merchantnameFilter}
              onChange={({target: {value}={}}) => setMerchantNameFilter(value)}
            />
            <Typography
              variant="subtitle1"
              component="div"
              style={{ marginBottom: 10 }}
            >
              Filter Date:
            </Typography>
            <BasicDateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <Button
              variant="contained"
              size="large"
              style={{
                alignItems: "center",
                width: 320,
                justifyContent: "space-around",
              }}
              onClick={() => {setFilterData()}}
            >
              filter
            </Button>
          </Container>
          <Puller />
        </StyledBox>

        {/* <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Skeleton variant="rectangular" height="100%"></Skeleton>
        </StyledBox> */}
      </SwipeableDrawer>
    </Root>
  );
}

Drawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Drawer;
