import React, { useContext, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Platform,
  StyleSheet,
  ScrollView,
  ImageBackground,
  ActivityIndicator,
  Alert,
} from "react-native";
import axios from "axios";
import FormInput from "../components/FormInput";
import FormButton from "../components/FormButton";
import ErrorMessage from "../components/ErrorMessage";
import ResetPassword from "./ResetPassword";
import {BASE_API_URL} from '../config';

const ForgotPassword = ({}) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isAuthorised, setAuthorised] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage("Please fill Email");
      return;
    } else {
      setMessage(null);
      try {
        const config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        setLoading(true);

        const response = await axios.post(
          `${BASE_API_URL}/api/password-reset`,
          { email },
          config
        );
        const { data } = response;
        {
          setMessage("Link has been sent to your email");
          // navigation.navigate("ResetPassword");
        }
        setLoading(false);
      } catch (error) {
        setError(error.response.data.message);
        setMessage("Email does not exists");
        setLoading(false);
      }
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.text}>Add email</Text>
      <FormInput
        labelValue={email}
        value={email}
        onChangeText={(e) => setEmail(e)}
        placeholderText="Email"
        iconType="email"
        keyboardType="email-address"
        autoCapitalize="none"
        autoCorrect={false}
      />

      {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
      {message && <ErrorMessage variant="danger">{message}</ErrorMessage>}
      {isLoading && <ActivityIndicator />}
      <FormButton buttonTitle="Send Link" onPress={submitHandler} />
    </ScrollView>
  );
};

export default ForgotPassword;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    paddingTop: 50,
  },
  logo: {
    height: "100%",
    width: "100%",
    resizeMode: "cover",
    flex: 1,
  },
  text: {
    fontFamily: "Kufam-SemiBoldItalic",
    fontSize: 28,
    marginBottom: 10,
    color: "#051d5f",
  },
  navButton: {
    marginTop: 15,
  },
  forgotButton: {
    marginVertical: 35,
  },
  navButtonText: {
    fontSize: 18,
    fontWeight: "500",
    color: "#2e64e5",
    fontFamily: "Lato-Regular",
  },
});
