import * as React from "react";
import getQueryString from "../utils/GetQueryString";
import {BASE_API_URL} from '../config';

const getExpenses = async ({ filter = {}, sort = {}, ...query }) => {
  const getToken = localStorage.getItem("userInfo");
  let Token = JSON.parse(getToken);
  const api = `${BASE_API_URL}/expenses?${getQueryString({
    ...query,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
  })}`;
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token.token}`,
    },
  };
  try {
    const response = await fetch(api, config);

    const json = await response.json();

    return json;
  } catch (error) {
    console.error(error);
  }
};

export default getExpenses;
