import * as React from "react";
import TextField from "@mui/material/TextField";
import { Container, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";

const defaultValues = {
  from: "",
  to: "",
};
export default function BasicDateRangePicker(props) {
  const { dateRange = defaultValues, setDateRange } = props;

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setDateRange({
      ...dateRange,

      [name]: value,
    });
  };
  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <Typography component="h1" variant="h4" type="left">
            Filter Date
          </Typography> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="outlined-required"
          name="from"
          helperText="From"
          type="date"
          fullWidth
          autoComplete="family-name"
          variant="outlined"
          size="small"
          value={dateRange.from}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="outlined-required"
          name="to"
          helperText="To"
          type="date"
          fullWidth
          autoComplete="family-name"
          variant="outlined"
          size="small"
          value={dateRange.to}
          onChange={handleInputChange}
        />
      </Grid>
    </Container>
  );
}
