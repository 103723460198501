// import Hidden from '@material-ui/core/Hidden';
import { SafeAreaView } from "react-native";
import BasicTable from "./Table";
import ButtonAppBar from "../Header";
const AccountsTable = () => {
  return (
    <SafeAreaView>
      <ButtonAppBar
        name="Shippay"
       
      />

      <BasicTable />
    </SafeAreaView>
  );
};

export default AccountsTable;
