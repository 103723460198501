import React, { useContext, useState, useEffect, Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Platform,
  StyleSheet,
  ScrollView,
  ImageBackground,
  ActivityIndicator,
  Alert,
} from "react-native";
import axios from "axios";
import FormInput from "../components/FormInput";
import FormButton from "../components/FormButton";
import SocialButton from "../components/SocialButton";
import ForgotPassword from "./ForgotPassword";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import ErrorMessage from "../components/ErrorMessage";
import { Redirect, useHistory } from "react-router-dom";
import {BASE_API_URL} from '../config';

const LogIn = ({}) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isAuthorised, setAuthorised] = useState(false);
  const [user, setUser] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage("Please fill Email");
      return;
    }
    if (!password) {
      setMessage("Please fill password");
      return;
    } else {
      setMessage(null);
      try {
        const config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        setLoading(true);

        const response = await axios.post(
          `${BASE_API_URL}/login`,

          {
            email,
            password,
          },
          config
        );
        const { data } = response;

        setAuthorised(data.authorised);
        if (!data.authorised) {
          setError(data.error);
        }
        if (data.authorised) {
          localStorage.setItem("userInfo", JSON.stringify(data));
          history.push("/expenses");
        }

        console.log(data);

        setLoading(false);
      } catch (error) {
        setError(error.response.data.message);
        setLoading(false);
      }
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <TextField
            required
            value={email}
            variant="outlined"
            id="outlined-required"
            name="email"
            label="Email Address"
            size="small"
            type="email-address"
            onChange={(e) => setEmail(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            value={password}
            variant="outlined"
            id="outlined-required"
            name="password"
            label="Password"
            size="small"
            type="email-address"
            onChange={(e) => setPassword(e)}
            fullWidth
          />
        </Grid> */}
      <FormInput
        labelValue={email}
        value={email}
        onChangeText={(e) => setEmail(e)}
        placeholderText="Email"
        iconType="email"
        keyboardType="email-address"
        autoCapitalize="none"
        autoCorrect={false}
      />

      <FormInput
        labelValue={password}
        value={password}
        onChangeText={(e) => setPassword(e)}
        placeholderText="Password"
        iconType="lock"
        secureTextEntry={true}
      />

      {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
      {isLoading && <ActivityIndicator />}

      <FormButton buttonTitle="Login" onPress={submitHandler} />
      <Button
        onClick={() => {
          history.push("/fp");
        }}
      >
        Forgot Password
      </Button>

      {/* <TouchableOpacity
        style={styles.forgotButton}
        onClick={() => {
          history.push("/fp");
        }}
      >
        <Text style={styles.navButtonText}>Forgot Password?</Text>
      </TouchableOpacity> */}

      {Platform.OS === "android" ? (
        <View>
          <SocialButton
            buttonTitle="Sign In with Facebook"
            btnType="facebook"
            color="#4867aa"
            backgroundColor="#e6eaf4"
            onPress={() => fbLogin()}
          />

          <SocialButton
            buttonTitle="Sign In with Google"
            btnType="google"
            color="#de4d41"
            backgroundColor="#f5e7ea"
            onPress={() => googleLogin()}
          />
        </View>
      ) : null}

      {/* </ImageBackground> */}
    </ScrollView>
  );
};

export default LogIn;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    paddingTop: 50,
  },
  logo: {
    height: "100%",
    width: "100%",
    resizeMode: "cover",
    flex: 1,
  },
  text: {
    fontFamily: "Kufam-SemiBoldItalic",
    fontSize: 28,
    marginBottom: 10,
    color: "#051d5f",
  },
  navButton: {
    marginTop: 15,
  },
  forgotButton: {
    marginVertical: 35,
  },
  navButtonText: {
    fontSize: 18,
    fontWeight: "500",
    color: "#2e64e5",
    fontFamily: "Lato-Regular",
  },
  ImageBackground: {
    flex: 1,
    resizeMode: "cover",
    width: "100%",
    alignItems: "center",
  },
});
