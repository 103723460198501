import React, { useState } from "react";
import {
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ButtonAppBar from "../Header";

import { useHistory, useLocation } from "react-router-dom";
import { useFonts, Inter_400Regular } from "@expo-google-fonts/inter";
import { WorkSans_400Regular } from "@expo-google-fonts/work-sans";
import countExpenses from "../../Services/CountService";

const UserBox = ({ setSearchFilter, status, filter }) => {
  const history = useHistory();
  const [isLoaded, setLoaded] = React.useState(false);
  const [flexDirection, setflexDirection] = useState("column");
  const [summary, setSummary] = useState({
    approved: 0,
    pending: 0,
    open: 0,
    rejected: 0,
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryParams = useQuery();
  React.useEffect(async () => {
    if (isLoaded) return;
    const user = queryParams.get("id");
    const { items = [] } = await countExpenses({ user });
    console.log(items);

    const summaryObject = items.reduce((final, current) => {
      final[current.status] = current.count;
      return final;
    }, summary);
    setSummary(summaryObject);

    setLoaded(true);
  });

  const setStatus = ({ newStatus = status }) => {
    console.log(filter);
    setSearchFilter({ ...filter, status: newStatus });
  };

  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    WorkSans_400Regular,
  });

  return (
    <SafeAreaView>
      <PreviewLayout
        label="My Expenses"
        selectedValue={flexDirection}
        setSelectedValue={setflexDirection}
        setStatus={setStatus}
        summary={summary}
      ></PreviewLayout>

      <Fab
        color="primary"
        size="large"
        aria-label="add"
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        <AddIcon
          size="large"
          onClick={() => {
            history.push("/expensesform");
          }}
        />
      </Fab>
    </SafeAreaView>
  );
};

const PreviewLayout = ({
  label,
  children,
  values,
  selectedValue,
  setSelectedValue,
  setStatus,
  summary,
  selectedStatusIndex,
}) => (
  <View style={{ padding: 10 }}>
    <Text style={styles.label}>{label}</Text>

    <View style={styles.row}>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => setStatus({ newStatus: "open" })}
        style={[styles.Openbutton, selectedValue === styles.selected]}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,

            justifyContent: "space-between",
          }}
        >
          <Text
            style={[
              styles.ObuttonLabel,
              selectedValue === styles.selectedLabel,
             
            ]}
          >
            Draft
          </Text>
          <Text
            style={{
              paddingHorizontal: 4,
              fontSize: 32,
              fontWeight: 500,
              lineHeight: 32,

              color: "#FFFFFF",
            }}
          >
            {summary.open}
          </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => setStatus({ newStatus: "pending" })}
        style={[styles.Pendingbutton, selectedValue === styles.selected]}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,

            justifyContent: "space-between",
          }}
        >
          <Text
            style={[styles.buttonLabel, selectedValue === styles.selectedLabel]}
          >
            Pending
          </Text>
          <Text
            style={{
              paddingHorizontal: 4,
              fontSize: 32,
              fontWeight: 500,
              lineHeight: 32,

              color: "black",
            }}
          >
            {summary.pending}
          </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => setStatus({ newStatus: "approved" })}
        style={[styles.Approvedbutton, selectedValue === styles.selected]}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,

            justifyContent: "space-between",
          }}
        >
          <Text
            style={[styles.buttonLabel, selectedValue === styles.selectedLabel]}
          >
            Approved
          </Text>
          <Text
            style={{
              paddingHorizontal: 4,
              fontSize: 32,
              fontWeight: 500,
              lineHeight: 32,

              color: "black",
            }}
          >
            {summary.approved}
          </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => setStatus({ newStatus: "rejected" })}
        style={[styles.Rejectedbutton, selectedValue === styles.selected]}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,

            justifyContent: "space-between",
          }}
        >
          <Text
            style={[styles.buttonLabel, selectedValue === styles.selectedLabel]}
          >
            Rejected
          </Text>
          <Text
            style={{
              paddingHorizontal: 4,
              fontSize: 32,
              fontWeight: 500,
              lineHeight: 32,

              color: "black",
            }}
          >
            {summary.rejected}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  </View>
);

const styles = StyleSheet.create({
  box: {
    width: 50,
    height: 50,
  },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  Openbutton: {
    marginTop: 10,
    paddingHorizontal: 4,
    paddingVertical: 18,
    backgroundColor: "steelblue",
    alignSelf: "flex-start",
    marginHorizontal: "1%",
    marginBottom: 6,
    minWidth: "48%",
    justifyContent: "space-evenly",
    textAlign: "justify",
    borderColor: "#186ADE",
    borderWidth: 1,
    borderRadius: 4,
    padding: 30,
  },
  Pendingbutton: {
    marginTop: 10,
    justifyContent: "space-evenly",
    paddingHorizontal: 4,
    paddingVertical: 18,
    backgroundColor: "#F0F4FA",
    alignSelf: "flex-start",
    marginHorizontal: "1%",
    marginBottom: 6,
    minWidth: "48%",
    textAlign: "justify",
    borderWidth: 1,
    borderColor: "#186ADE",
    borderRadius: 4,
  },
  Approvedbutton: {
    marginTop: 10,
    justifyContent: "space-evenly",
    paddingHorizontal: 4,
    paddingVertical: 18,
    backgroundColor: "#EBF7ED",
    alignSelf: "flex-start",
    marginHorizontal: "1%",
    marginBottom: 6,
    minWidth: "48%",
    textAlign: "justify",
    borderWidth: 1,
    borderColor: "#077D55",
    borderRadius: 4,
  },
  Rejectedbutton: {
    justifyContent: "space-evenly",
    marginTop: 10,
    paddingHorizontal: 4,
    paddingVertical: 18,
    backgroundColor: "#FCF3F2",
    alignSelf: "flex-start",
    marginHorizontal: "1%",
    marginBottom: 6,
    minWidth: "48%",
    textAlign: "justify",
    borderWidth: 1,
    borderColor: "#D91F11",
    borderRadius: 4,
  },
  selected: {
    backgroundColor: "coral",
    borderWidth: 0,
  },
  buttonLabel: {
    fontFamily: "Inter_400Regular",
    fontSize: 16,
    fontWeight: "400",
    color: "white",
    letterspacing: 0,
    textAlign: "left",
    color: "black",
    lineHeight: 24,
    justifyContent: "space-between",
  },
  ObuttonLabel: {
    fontFamily: "Inter_400Regular",
    fontSize: 16,
    fontWeight: "400",
    color: "white",
    letterspacing: 0,
    textAlign: "left",
    color: "#FFFFFF",
    lineHeight: 24,
  },
  selectedLabel: {
    color: "white",
  },
  label: {
    fontFamily: "  WorkSans_400Regular",
    fontSize: 32,
    fontWeight: 400,
    lineHeight: 40,
    letterspacing: 0,
    textAlign: "left",
    color: "#0A0A0A",
    justifyContent: "space-between",
  },
});

export default UserBox;
