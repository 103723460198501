import * as React from "react";
import { SafeAreaView } from "react-native";
import ButtonAppBar from "../components/Header";
import UsersList from "../components/users-list/Users";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Container from "@mui/material/Container";
import { useHistory } from "react-router-dom";

const AccountsView = () => {
  const history = useHistory();
  return (
    <SafeAreaView>
      <ButtonAppBar
        name="Shippay"
      />

      <UsersList />
      <Fab
        color="primary"
        size="large"
        aria-label="add"
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        <PersonAddAltIcon
          onClick={() => {
            history.push("/create");
          }}
        />
      </Fab>
    </SafeAreaView>
  );
};

export default AccountsView;
