import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Redirect, useHistory } from "react-router-dom";
import ErrorMessage from "../components/ErrorMessage";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ButtonAppBar from "../components/Header";
import { SafeAreaView } from "react-native";
import {BASE_API_URL} from '../config';
import {
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  FormControl,
  FormLabel,
} from "@mui/material";

const theme = createTheme();
const defaultValues = {
  name: "",
  email: "",
  phoneNo: "",
  currency: "",
  createdAt: new Date(),
};

export default function CreateUser() {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);

  const [message, setMessage] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email, phoneNo, currency } = formValues;

    fetch(`${BASE_API_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phoneNo,
        currency,
        createdAt: new Date(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setMessage("User created ,Link has been sent to your email");
      });
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <SafeAreaView>
      <ButtonAppBar
        name="Shippay"
      />
      <form onSubmit={handleSubmit}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBar
            position="absolute"
            color="default"
            elevation={0}
            sx={{
              position: "relative",
              borderBottom: (t) => `1px solid ${t.palette.divider}`,
            }}
          ></AppBar>
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Typography component="h1" variant="h4" align="left">
                Add User
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="outlined-required"
                    name="name"
                    label="Enter Users Name"
                    type="text"
                    value={formValues.name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="outlined-required"
                    name="email"
                    label="Enter Users email"
                    type="text"
                    value={formValues.email}
                    onChange={handleInputChange}
                  />
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    size="small"
                    id="outlined-required"
                    type="text"
                    label="Enter Users PhoneNo"
                    value={formValues.phoneNo}
                    onChange={handleInputChange}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    size="small"
                    id="outlined-required"
                    name="currency"
                    label="Enter Users currency"
                    type="text"
                    value={formValues.currency}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    size="small"
                    id="outlined-required"
                    name="phoneNo"
                    label="Enter Users PhoneNo"
                    type="number"
                    value={formValues.phoneNo}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              {message && (
                <ErrorMessage variant="danger">{message}</ErrorMessage>
              )}
              <React.Fragment>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    sx={{ mt: 3, ml: 1 }}
                    variant="contained"
                  >
                    Create
                  </Button>

                  <Button
                    noValidate
                    type="submit"
                    sx={{ mt: 3, ml: 1 }}
                    variant="contained"
                    onClick={() => {
                      history.push("/users");
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </React.Fragment>
            </Paper>
          </Container>
        </ThemeProvider>
      </form>
    </SafeAreaView>
  );
}
