import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Text, SafeAreaView, View } from "react-native";
import getExpenses from "../Services/ExpenseService";
import Avatar from "@mui/material/Avatar";
import ButtonAppBar from "../components/Header";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import CircularProgress from '../components/CircularProgress';

import moment from "moment";
import {
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  FormControl,
  FormLabel,
} from "@mui/material";

import getSingleExpenses from "../Services/SingleExpense";
import { useEffect } from "react";
import WebViewInvoice from './WebViewInvoice';
import { BASE_API_URL } from '../config';

const theme = createTheme();
const defaultValues = {
  merchantname: "",
  invoiceCreatedDate: "",
  invoiceSubmittedDate: "",
  invoiceValue: "",
  category: "",
  description: "",
  status: "",
  updatedAt: "",
  createdAt: new Date(),
};

export default function UpdateExpense() {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [picMessage, setPicMessage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [invoiceFile, setInvoiceFile] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);

  const [message, setMessage] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const userInfo = localStorage.getItem("userInfo");
  let currentUser = JSON.parse(userInfo);

  const { _id } = useParams();

  const previousIsLoaded = usePrevious(isLoaded);
  React.useEffect(async () => {
    if (isLoaded) return;
    const response = await getSingleExpenses(_id);
    if (response.status === 200) {
      const {
        data: {
          merchantname,
          invoiceCreatedDate,
          invoiceSubmittedDate,
          invoiceValue,
          category,
          description,
          status,
          updatedAt,
          createdAt,
          currency,
          exchangeRate,
          fileUrl,
          fileMimeType,
        } = {},
      } = response;
      setFormValues({
        ...formValues,
        merchantname,
        invoiceCreatedDate,
        invoiceSubmittedDate,
        invoiceValue,
        category,
        description,
        status,
        updatedAt,
        createdAt,
        currency,
        exchangeRate,
        fileUrl,
        fileMimeType,
      });
      setIsLoaded(true);
    }
  });

  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const handleSubmit = async (event) => {
    const url = `${BASE_API_URL}/update/${_id}`;
    event.preventDefault();
    const {
      merchantname,
      invoiceCreatedDate,
      description,
      invoiceValue,
      status,
      category,
      currency,
      exchangeRate,
      fileMimeType,
      fileUrl,
    } = formValues;

    const getToken = localStorage.getItem("userInfo");
    let Token = JSON.parse(getToken);
    
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token.token}`,
      },
      body: JSON.stringify({
        merchantname,
        invoiceCreatedDate,
        invoiceValue,
        category,
        description,
        status,
        currency,
        exchangeRate,
        fileMimeType,
        fileUrl,
        ...(invoiceFile.url ? {file: invoiceFile}: {})
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        history.goBack();
      });
  };
  const postDetails = async (pics) => {
  
    const getToken = localStorage.getItem("userInfo");
    let {token} = JSON.parse(getToken)||{};
    if (['application/zip', 'application/pdf', 'image/jpeg', 'image/png',].includes(pics.type)) {
      setIsUploading(true);
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "Invoice");
      const {data:uploadedFileDetails, status,} = await axios.post(`${BASE_API_URL}/upload`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((100 * progressEvent.loaded) / progressEvent.total);
          setUploadProgress(percentage);
        }
      });
      setIsUploading(false);
      if (status != 200)
        return console.error('Failed to upload');
      setInvoiceFile(uploadedFileDetails);
    } else {
      return setPicMessage("Please Select a JPEG/PNG/PDF file");
    }
  };
  if (!isLoaded) return <Text>Loading...</Text>;
  return (
    <SafeAreaView>
      <ButtonAppBar
        name="Shippay"
      />
      <form onSubmit={handleSubmit}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBar
            position="absolute"
            color="default"
            elevation={0}
            sx={{
              position: "relative",
              borderBottom: (t) => `1px solid ${t.palette.divider}`,
            }}
          ></AppBar>
          <Container component="main" maxWidth="false" sx={{ mb: 4, mt: 4 }}>
            <Card>
              <CardContent sx={{ pr: 2, pl: 2 }}>
                <Paper
                  variant="outlined"
                  sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                >
                  <Grid container spacing={2}>
                    <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center'>
                      <Grid item xs={3} >
                        <Typography component="h1" variant="h4" type="center" sx={{ mb: 2, mt: 4 }}>
                          Edit Expense
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {
                        isUploading && <Box style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress value={uploadProgress}/></Box>
                      }
                      {
                        !isUploading && (
                          (invoiceFile?.url || formValues?.fileUrl) ?
                            <View style={{ height: '100%', display: 'flex' }}>
                              <WebViewInvoice uri={invoiceFile?.url||formValues?.fileUrl} fileMimeType={invoiceFile?.contentType||formValues?.fileMimeType} />
                               <Button variant="outlined" component="label" color="primary" sx={{mt:1}}>
                                  Change Upload
                                  <input
                                    accept="image/jpeg/pdf"
                                    type="file"
                                    name="invoiceFile"
                                    onChange={(e) => postDetails(e.target.files[0])}
                                    value={formValues.invoiceFile}
                                    hidden
                                  />
                              </Button>
                            </View>
                            :
                            <Box component="div" display='flex' alignItems='center' justifyContent='center' sx={{ p: 2, border: '1px dashed ', height: '100%' }}>
                              <Button variant="outlined" component="label" color="primary">
                              Upload
                              <input
                                accept="image/jpeg/pdf"
                                type="file"
                                name="invoiceFile"
                                value={formValues.invoiceFile}
                                onChange={(e) => postDetails(e.target.files[0])}
                                hidden
                              />
                          </Button>
                            </Box>
                        )
                      }

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            id="outlined-required"
                            size="small"
                            fullWidth
                            name="merchantname"
                            label="Merchant Name"
                            type="text"
                            value={formValues.merchantname}
                            onChange={handleInputChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            id="outlined-required"
                            size="small"
                            fullWidth
                            name="invoiceCreatedDate"
                            helperText="Invoice created date"
                            type="date"
                            // defaultValue="2017-05-24"

                            autoComplete="family-name"
                            value={
                              formValues.invoiceCreatedDate
                                ? moment(formValues.invoiceCreatedDate).format(
                                  "YYYY-MM-DD"
                                )
                                : ""
                            }
                            onChange={handleInputChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            id="outlined-required"
                            size="small"
                            fullWidth
                          >
                            <InputLabel> Category</InputLabel>
                            <Select
                              label="Category"
                              name="category"
                              value={formValues.category}
                              onChange={handleInputChange}
                            >
                              <MenuItem value={"Tech Expenses "}>Tech Expenses</MenuItem>
                              <MenuItem value={"Food"}>Food</MenuItem>
                              <MenuItem value={"Transport"}>Transport</MenuItem>

                              <MenuItem value={"Client Meeting"}>
                                Client Meeting
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                  <Converter />
                </Grid> */}
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            id="outlined-required"
                            size="small"
                            fullWidth
                            type="number"
                            name="invoiceValue"
                            label="Invoice value"
                            autoComplete="Iv"
                            value={formValues.invoiceValue}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            variant="outlined"
                            id="outlined-required"
                            size="small"
                            fullWidth
                            required
                          >
                            <InputLabel> Select Currency</InputLabel>
                            <Select
                              label="Select Currency"
                              name="currency"
                              value={formValues.currency}
                              onChange={handleInputChange}
                            >
                              <MenuItem value={'USD'}>USD</MenuItem>
                              <MenuItem value={'INR'}>INR</MenuItem>
                              <MenuItem value={'AED'}>AED</MenuItem>
                              <MenuItem value={'EUR'}>EUR</MenuItem>
                              <MenuItem value={'GBP'}>GBP</MenuItem>
                              <MenuItem value={'SGD'}>SGD</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            variant="outlined"
                            id="outlined-required"
                            size="small"
                            fullWidth
                            required
                          >
                            <TextField
                              name="exchangeRate"
                              variant="outlined"
                              id="outlined-required"
                              size="small"
                              type="number"
                              label="Enter Exchange Rate"
                              required
                              default={1}
                              value={formValues.exchangeRate}
                              onChange={handleInputChange}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="description"
                            name="description"
                            label="Enter description"
                            multiline
                            fullWidth
                            rows="3"
                            margin="normal"
                            variant="outlined"
                            value={formValues.description}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {/* <Button variant="outlined" component="label" color="primary">
                            {" "}
                            Upload
                            <input
                              accept="image/jpeg/pdf"
                              type="file"
                              name="invoiceImg"
                              value={invoiceImg || ""}
                              onChange={(e) => postDetails(e.target.files[0])}
                              hidden
                            />
                          </Button> */}
                        </Grid>
                         
                        {currentUser?.roles.includes("Accountant") ? (
                          <Grid item xs={12}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel> status</InputLabel>
                              <Select
                                label="Enter status"
                                name="status"
                                value={formValues.status}
                                onChange={handleInputChange}
                              >
                                <MenuItem value={"open"}>open</MenuItem>
                                <MenuItem value={"pending"}>pending</MenuItem>
                                <MenuItem value={"approved"}>approved</MenuItem>
                                <MenuItem value={"rejected"}>rejected</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : (
                          <> </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      sx={{ mt: 3, ml: 1 }}
                      variant="contained"
                    >
                      Save
                    </Button>
                    <Button
                      type="submit"
                      sx={{ mt: 3, ml: 1 }}
                      variant="contained"
                      noValidate
                      onClick={() => {
                        history.push("/expenses");
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Paper>
              </CardContent>
            </Card>
          </Container>
        </ThemeProvider>
      </form>
    </SafeAreaView>
  );
}
